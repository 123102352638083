const getCurrentChannel = (state) => state;

const getLoading = (state) => state.loading;

const getLoadingResult = (state) => state.loadingResult;

const getBtnResults = (state) => state.objCoTypeOfBetting;

const getSummaryResult = (state) => state.objSummaryCoResult;

const getResultTable = (state) => state.objCoResult;

const getKdPayout = (state) => state.objKdPayout;

const getdialogResult = (state) => state.dialogResult;

const getLastResult = (state) => state.lastResult;

const getSkipColumn = (state) => state.resultSkipColumn;
const getSkipRow = (state) => state.resultSkipRow;
const getSkipBetween = (state) => state.isNoResultBetween;

const getDealerInfo = (state) => state.dealerInfo;

const getDealerDialog = (state) => state.dealerDialog;

const getResultEvent = (state) => state.resultEvent;

const getCurrentCard = (state) => state.currentCard;

const getAllowScanCard = (state) => state.allowScanCard;

export default {
  getDealerDialog,
  getDealerInfo,
  getCurrentChannel,
  getBtnResults,
  getSummaryResult,
  getResultTable,
  getLoading,
  getLoadingResult,
  getKdPayout,
  getdialogResult,
  getLastResult,
  getSkipColumn,
  getSkipRow,
  getSkipBetween,
  getResultEvent,
  getCurrentCard,
  getAllowScanCard,
};
