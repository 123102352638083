<template>
  <div>
      <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import storeModules from "@/modules/_store";
import storeAuth from "@/modules/auth/_store";
import storeUser from "@/modules/user/_store";
import storeUserSubAgent from "@/modules/userSubAgent/_store";
import storeMenu from "@/modules/MenuManagement/_store";
import storeTransaction from "@/modules/Transaction/_store";
import storeWidthdrawStatement from "@/modules/widthdrawStatement/_store";
import storeDepositStatement from "@/modules/depositStatement/_store";
import storeTopUp from "@/modules/topUp/_store";
import storeRole from "@/modules/roles/_store";
import storePermission from "@/modules/permission/_store";
import storeChannel from "@/modules/channel/_store";
import storeStatement from "@/modules/statement/_store";
import storeStatementUsd from "@/modules/statementReports/channel/ocStatementUsd/_store";
import storeStatementKh from "@/modules/statementReports/channel/ocStatementKh/_store";
import storeOptionOperation from "@/modules/setOptionOperation/_store";
// import storeReportOutStanding from "@/modules/reportOutStanding/_store";

import storeVipPercentage from "@/modules/vipPercentage/_store";

//cockfing
// import storeCockFightChannel from "@/modules/cockFightChannel/_store";
import storeReverseResult from "@/modules/reverseResult/cock/_store";

import storeReverseBaccarat from "@/modules/reverseResult/baccarat/_store";
import storeReverseDragon from "@/modules/reverseResult/dragon/_store";//_reverseDragon
import storeConfigUser from "@/modules/configUser/_store";
import storePayOut from "@/modules/payOut/_store";
import storeWeight from "@/modules/weight/_store";
import storeRoleLevel from "@/modules/roleLevel/_store";
import storeControllerForManager from "@/modules/controllerForManager/_store";
import storeOcStatementSummary from "@/modules/statementReports/currency/ocStatementSummary/_store";
import storeOcStatementSummaryKm from "@/modules/statementReports/currency/ocStatementSummaryCompanyKm/_store";
import storeOcStatementSummarySeniorUSD from "@/modules/statementReports/currency/ocStatementSummarySeniorUSD/_store";
import storeOcStatementSummarySeniorKm from "@/modules/statementReports/currency/ocStatementSummarySeniorKm/_store";
import storeOcStatementSummaryMasterUSD from "@/modules/statementReports/currency/ocStatementSummaryMasterUSD/_store";
import storeOcStatementSummaryMasterKm from "@/modules/statementReports/currency/ocStatementSummaryMasterKm/_store";
import storeOcStatementUSD from "@/modules/statementReports/channel/ocStatementUsd/_store";
import storeOcStatementKH from "@/modules/statementReports/channel/ocStatementKh/_store";
import storeEnableDisablePlayer from "@/modules/enableDisablePlayer/_store";
import storeEnableDisableAgent from "@/modules/enableDisableAgent/_store";
import storeOcStatementSummaryPlayerUSD from "@/modules/ocControlStatementSummaryPlayerUSD/_store";

// Channel
import storeCockFightChannel from "@/modules/channelGames/cockChannel/_store";
import storeApongChannel from "@/modules/channelGames/apongChannel/_store";
import storeBaccaratChannel from "@/modules/channelGames/baccaratChannel/_store";
import storeDragonChannel from "@/modules/channelGames/dragonChannel/_store";
import storeKlakloukChannel from "@/modules/channelGames/klakloukChannel/_store";
import storeLottoChannel from "@/modules/channelGames/lottoChannel/_store";
import storeYukiChannel from "@/modules/channelGames/yukiChannel/_store";

// Kd-Payout
import storeKdPayout from "@/modules/setPayout/_store";
//Kd
import storeCompanyReport from "@/modules/companyReports/_store";
import storeSeniorReport from "@/modules/seniorReports/_store";
import storeMasterReport from "@/modules/masterReports/_store";
import storeAgentReport from "@/modules/agentReports/_store";
import storePlayerReport from "@/modules/playerReports/_store";
import storeUserOnlineReport from "@/modules/userOnlineReports/_store";
import storeTopRangeWinnerReportsReport from "@/modules/topRangeWinnerReports/_store";
import storeHistoryByFightReport from "@/modules/historyByFightReport/_store";
import storeSeniorManagePlayer from "@/modules/seniorManagePlayer/_store";
import storeTreeStatementUSD from "@/modules/statementReports/channel/treeStatementUSD/_store";
import storeCancelBetting from "@/modules/cancelBetting/_store";

import storeConfigApp from "@/modules/configApp/_store";
import storeDealer from "@/modules/dealer/_store";
// import storeMaintenance from "@/modules/maintenance/_store";

//outStanding Reports
import storeCockFightOutStandingReport from "@/modules/outstandingReports/cockFight/_store";
import storeCockFightOutStandingReportCm from "@/modules/outstandingReports/cockFightCm/_store";
import storeAbongOutStandingReport from "@/modules/outstandingReports/abong/_store";
import storeAbongOutStandingReportCm from "@/modules/outstandingReports/abongCm/_store";
import storeBaccaratOutStandingReport from "@/modules/outstandingReports/baccarat/_store";
import storeBaccaratOutStandingReportCm from "@/modules/outstandingReports/baccaratCm/_store";
import storeDragonOutStandingReport from "@/modules/outstandingReports/dragon/_store";
import storeDragonOutStandingReportCm from "@/modules/outstandingReports/dragonCm/_store";
import storeKlakloukOutStandingReport from "@/modules/outstandingReports/klaklouk/_store";
import storeKlakloukOutStandingReportCm from "@/modules/outstandingReports/klakloukCm/_store";
import storeLottoOutStandingReport from "@/modules/outstandingReports/lotto/_store";
import storeLottoOutStandingReportCm from "@/modules/outstandingReports/lottoCm/_store";
import storeYukiOutStandingReport from "@/modules/outstandingReports/yuki/_store";
import storeYukiOutStandingReportCm from "@/modules/outstandingReports/yukiCm/_store";
import storeFantanOutStandingReport from "@/modules/outstandingReports/fantan/_store";
import storeFantanOutStandingReportCm from "@/modules/outstandingReports/fantanCm/_store";
import storeTaisievOutStandingReport from "@/modules/outstandingReports/taisiev/_store";
import storeTaisievOutStandingReportCm from "@/modules/outstandingReports/taisievCm/_store";
import storeHan3OutStandingReportCm from "@/modules/outstandingReports/han3Cm/_store";

// Type of Game report
import storeTypeGameSummaryCompany from "@/modules/statementReports/typeGame/typeGameSummaryCompany/_store";
import storeTypeGameSummarySenior from "@/modules/statementReports/typeGame/typeGameSummarySenior/_store";
import storeTypeGameSummaryMaster from "@/modules/statementReports/typeGame/typeGameSummaryMaster/_store";
import storeTypeGameSummaryAgent from "@/modules/statementReports/typeGame/typeGameSummaryAgent/_store";
import storeTypeGameSummaryPlayer from "@/modules/statementReports/typeGame/typeGameSummaryPlayer/_store";

export default {
  name: "App",
  created() {
    this.registerStore();
    this.initData();
  },
  computed: {
    ...mapGetters("$_modules", {
      user: "getUserInfo"
    }),
  },
  methods: {
    initData() {
      const token = this.$cookies.get("token");
      if (!token) return;
      this.fetchUserInfo();
    },
    registerStore() {
      const CANCEL_BETTING = "$_cancelBetting";
      const VIP_PERCENTAGE = "$_vipPercentage";
      const TREE_STATEMENT_USD = "$_treeStatementUSD";
      const MODULES = "$_modules";
      const AUTH = "$_auth";
      const USER = "$_user";
      const USER_SUB_AGENT = "$_userSubAgent";
      const MENUS = "$_menu";
      const TRANSACTION = "$_transaction";
      const WidthdrawStatement = "$_widthdrawStatement";
      const DepositStatement = "$_depositStatement";
      const TOPUP = "$_topup";
      const ROLE = "$_role";
      const PERMISSION = "$_permission";
      const CHANNEL = "$_channel";
      const STATEMENT = "$_statement";
      const STATEMENT_USD = "$_statementUsd";
      const STATEMENT_KH = "$_statementKh";
      //cockfight
      const CockFightChannel = "$_cockFightChannel";
      //reverse result
      const ReverseResult = "$_reverseResult";
      const ReverseBaccarat = "$_reverseBaccarat";
      const ReverseDragon = "$_reverseDragon"
      ///////
      const ConfigUser = "$_configUser";
      const PayOut = "$_payOut";
      const WEIGHT = "$_weight";
      const RoleLevel = "$_roleLevel";
      const ControllerForManager = "$_controllerForManager";
      const OcStatementSummary = "$_oCStatementSummary";
      const OcStatementSummaryKm = "$_oCStatementSummaryKm";
      const OcStatementSummarySeniorUSD = "$_ocStatementSummarySeniorUSD";
      const OcStatementSummarySeniorKm = "$_ocStatementSummarySeniorKm";
      const OcStatementSummaryMasterUSD = "$_ocStatementSummaryMasterUSD";
      const OcStatementSummaryMasterKm = "$_ocStatementSummaryMasterKm";
      const OcStatementKh = "$_ocStatementKh";
      const OcStatementUsd = "$_ocStatementUsd";
      const EnableDisablePlayer = "$_enableDisablePlayer";
      const EnableDisableAgent = "$_enableDisableAgent";
      const OcControlStatementSummaryPlayerUSD = "$_ocControlStatementSummaryPlayerUSD";
      const OPTIONOPERATION = "$_optionOperation";
      //KdPayout
      const KDPAYOUT = "$_kdPayout";
       //kd
      const STATEMENT_COMPANY_REPORTS = "$_statementCompanyReports";
      const STATEMENT_SENIOR_REPORTS = "$_statementSeniorReports";
      const STATEMENT_MASTER_REPORTS = "$_statementMasterReports";
      const STATEMENT_AGENT_REPORTS = "$_statementAgentReports";
      const STATEMENT_PLAYER_REPORTS = "$_statementPlayerReports"; 
      const STATEMENT_USER_ONLINE_REPORTS = "$_statementUserOnlineReports"; 
      const STATEMENT_TOP_RANG_WINNER_REPORTS = "$_statementTopRangeWinnerReports"; 
      const HistoryByFightReport = "$_historyByFightReport"; 
      const SENIOR_MANAGE_PLAYER = "$_seniorManagePlayer"; 
      
      const ConfigApp = "$_configApp";
     
      //Channel
      const ApongChannel = "$_apongChannel";
      const BaccaratChannel = "$_baccaratChannel";
      const DragonChannel = "$_dragonChannel";
      const KlakloukChannel = "$_klakloukChannel";
      const LottoChannel = "$_lottoChannel";
      const YukiChannel = "$_yukiChannel";

      // Outstanding
      const COCKFIGHT_REPORT_OUT_STANDING = "$_cockFightReportOutStanding";
      const COCKFIGHT_REPORT_OUT_STANDING_CM = "$_cockFightReportOutStandingCm";
      const ABONG_REPORT_OUT_STANDING = "$_abongReportOutStanding";
      const ABONG_REPORT_OUT_STANDING_CM = "$_abongReportOutStandingCm";
      const BACCARAT_REPORT_OUT_STANDING = "$_baccaratReportOutStanding";
      const BACCARAT_REPORT_OUT_STANDING_CM = "$_baccaratReportOutStandingCm";
      const DRAGON_REPORT_OUT_STANDING = "$_dragonReportOutStanding";
      const DRAGON_REPORT_OUT_STANDING_CM = "$_dragonReportOutStandingCm";
      const KLAKLOUK_REPORT_OUT_STANDING = "$_klakloukReportOutStanding";
      const KLAKLOUK_REPORT_OUT_STANDING_CM = "$_klakloukReportOutStandingCm";
      const LOTTO_REPORT_OUT_STANDING = "$_lottoReportOutStanding";
      const LOTTO_REPORT_OUT_STANDING_CM = "$_lottoReportOutStandingCm";
      const YUKI_REPORT_OUT_STANDING = "$_yukiReportOutStanding";
      const YUKI_REPORT_OUT_STANDING_CM = "$_yukiReportOutStandingCm";
      const FANTAN_REPORT_OUT_STANDING = "$_fantanReportOutStanding";
      const FANTAN_REPORT_OUT_STANDING_CM = "$_fantanReportOutStandingCm";
      const TAISIEV_REPORT_OUT_STANDING = "$_taisievReportOutStanding";
      const TAISIEV_REPORT_OUT_STANDING_CM = "$_taisievReportOutStandingCm";
      const HAN3_REPORT_OUT_STANDING_CM = "$_han3ReportOutStandingCm";
      

      // Type Game
      const TypeGameSummaryCompany = "$_typeGameSummaryCompany";
      const TypeGameSummarySenior = "$_typeGameSummarySenior";
      const TypeGameSummaryMaster = "$_typeGameSummaryMaster";
      const TypeGameSummaryAgent = "$_typeGameSummaryAgent";
      const TypeGameSummaryPlayer = "$_typeGameSummaryPlayer";

      if (!(TypeGameSummaryCompany in this.$store._modules.root._children)) {
        this.$store.registerModule(TypeGameSummaryCompany, storeTypeGameSummaryCompany);
      }
      if (!(TypeGameSummarySenior in this.$store._modules.root._children)) {
        this.$store.registerModule(TypeGameSummarySenior, storeTypeGameSummarySenior);
      }
      if (!(TypeGameSummaryMaster in this.$store._modules.root._children)) {
        this.$store.registerModule(TypeGameSummaryMaster, storeTypeGameSummaryMaster);
      }
      if (!(TypeGameSummaryAgent in this.$store._modules.root._children)) {
        this.$store.registerModule(TypeGameSummaryAgent, storeTypeGameSummaryAgent);
      }
      if (!(TypeGameSummaryPlayer in this.$store._modules.root._children)) {
        this.$store.registerModule(TypeGameSummaryPlayer, storeTypeGameSummaryPlayer);
      }

      if (!(HistoryByFightReport in this.$store._modules.root._children)) {
        this.$store.registerModule(HistoryByFightReport, storeHistoryByFightReport);
      }
      if (!(COCKFIGHT_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(COCKFIGHT_REPORT_OUT_STANDING, storeCockFightOutStandingReport);
      }
      if (!(COCKFIGHT_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(COCKFIGHT_REPORT_OUT_STANDING_CM, storeCockFightOutStandingReportCm);
      }
      if (!(ABONG_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(ABONG_REPORT_OUT_STANDING, storeAbongOutStandingReport);
      }
      if (!(ABONG_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(ABONG_REPORT_OUT_STANDING_CM, storeAbongOutStandingReportCm);
      }
      if (!(BACCARAT_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(BACCARAT_REPORT_OUT_STANDING, storeBaccaratOutStandingReport);
      }
      if (!(BACCARAT_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(BACCARAT_REPORT_OUT_STANDING_CM, storeBaccaratOutStandingReportCm);
      }
      if (!(DRAGON_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(DRAGON_REPORT_OUT_STANDING, storeDragonOutStandingReport);
      }
      if (!(DRAGON_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(DRAGON_REPORT_OUT_STANDING_CM, storeDragonOutStandingReportCm);
      }
      if (!(KLAKLOUK_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(KLAKLOUK_REPORT_OUT_STANDING, storeKlakloukOutStandingReport);
      }
      if (!(KLAKLOUK_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(KLAKLOUK_REPORT_OUT_STANDING_CM, storeKlakloukOutStandingReportCm);
      }
      if (!(LOTTO_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(LOTTO_REPORT_OUT_STANDING, storeLottoOutStandingReport);
      }
      if (!(LOTTO_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(LOTTO_REPORT_OUT_STANDING_CM, storeLottoOutStandingReportCm);
      }
      if (!(YUKI_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(YUKI_REPORT_OUT_STANDING, storeYukiOutStandingReport);
      }
      if (!(YUKI_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(YUKI_REPORT_OUT_STANDING_CM, storeYukiOutStandingReportCm);
      }
      if (!(FANTAN_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(FANTAN_REPORT_OUT_STANDING, storeFantanOutStandingReport);
      }
      if (!(FANTAN_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(FANTAN_REPORT_OUT_STANDING_CM, storeFantanOutStandingReportCm);
      }
      if (!(TAISIEV_REPORT_OUT_STANDING in this.$store._modules.root._children)) {
        this.$store.registerModule(TAISIEV_REPORT_OUT_STANDING, storeTaisievOutStandingReport);
      }
      if (!(TAISIEV_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(TAISIEV_REPORT_OUT_STANDING_CM, storeTaisievOutStandingReportCm);
      }
      if (!(HAN3_REPORT_OUT_STANDING_CM in this.$store._modules.root._children)) {
        this.$store.registerModule(HAN3_REPORT_OUT_STANDING_CM, storeHan3OutStandingReportCm);
      }

      const DEALER = "$_dealer";
      if (!(DEALER in this.$store._modules.root._children)) {
          this.$store.registerModule(DEALER, storeDealer);
      }
      // const MAINTENANCE = "$_maintenance";
      // if (!(MAINTENANCE in this.$store._modules.root._children)) {
      //     this.$store.registerModule(MAINTENANCE, storeMaintenance);
      // }

      if (!(ApongChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(ApongChannel, storeApongChannel);
      }
      if (!(BaccaratChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(BaccaratChannel, storeBaccaratChannel);
      }
      if (!(DragonChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(DragonChannel, storeDragonChannel);
      }
      if (!(KlakloukChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(KlakloukChannel, storeKlakloukChannel);
      }
      if (!(LottoChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(LottoChannel, storeLottoChannel);
      }
      if (!(YukiChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(YukiChannel, storeYukiChannel);
      }
      if (!(ConfigApp in this.$store._modules.root._children)) {
          this.$store.registerModule(ConfigApp, storeConfigApp);
      }
      if (!(CANCEL_BETTING in this.$store._modules.root._children)) {
        this.$store.registerModule(CANCEL_BETTING, storeCancelBetting);
      }

      if (!(VIP_PERCENTAGE in this.$store._modules.root._children)) {
        this.$store.registerModule(VIP_PERCENTAGE, storeVipPercentage);
      }
        if (!(TREE_STATEMENT_USD in this.$store._modules.root._children)) {
        this.$store.registerModule(TREE_STATEMENT_USD, storeTreeStatementUSD);
      }
       if (!(SENIOR_MANAGE_PLAYER in this.$store._modules.root._children)) {
        this.$store.registerModule(SENIOR_MANAGE_PLAYER, storeSeniorManagePlayer);
      }
      if (!(STATEMENT_COMPANY_REPORTS in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_COMPANY_REPORTS, storeCompanyReport);
      }
      if (!(STATEMENT_SENIOR_REPORTS in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_SENIOR_REPORTS, storeSeniorReport);
      }
      if (!(STATEMENT_MASTER_REPORTS in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_MASTER_REPORTS, storeMasterReport);
      }
      if (!(STATEMENT_AGENT_REPORTS in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_AGENT_REPORTS, storeAgentReport);
      }
      if (!(STATEMENT_PLAYER_REPORTS in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_PLAYER_REPORTS, storePlayerReport);
      }
      if (!(STATEMENT_USER_ONLINE_REPORTS in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_USER_ONLINE_REPORTS, storeUserOnlineReport);
      }
      if (!(STATEMENT_TOP_RANG_WINNER_REPORTS in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_TOP_RANG_WINNER_REPORTS, storeTopRangeWinnerReportsReport);
      }

      if (!(OPTIONOPERATION in this.$store._modules.root._children)) {
        this.$store.registerModule(OPTIONOPERATION, storeOptionOperation);
      }
      if (!(OcControlStatementSummaryPlayerUSD in this.$store._modules.root._children)) {
        this.$store.registerModule(OcControlStatementSummaryPlayerUSD, storeOcStatementSummaryPlayerUSD);
      }
      if (!(EnableDisablePlayer in this.$store._modules.root._children)) {
        this.$store.registerModule(EnableDisablePlayer, storeEnableDisablePlayer);
      }
      if (!(EnableDisableAgent in this.$store._modules.root._children)) {
        this.$store.registerModule(EnableDisableAgent, storeEnableDisableAgent);
      }
      if (!(OcStatementSummaryMasterUSD in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummaryMasterUSD, storeOcStatementSummaryMasterUSD);
      }
      if (!(OcStatementSummaryMasterKm in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummaryMasterKm, storeOcStatementSummaryMasterKm);
      }
      if (!(OcStatementUsd in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementUsd, storeOcStatementUSD);
      }
      if (!(OcStatementKh in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementKh, storeOcStatementKH);
      }
      if (!(OcStatementSummarySeniorUSD in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummarySeniorUSD, storeOcStatementSummarySeniorUSD);
      }
      if (!(OcStatementSummarySeniorKm in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummarySeniorKm, storeOcStatementSummarySeniorKm);
      }
      if (!(OcStatementSummary in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummary, storeOcStatementSummary);
      }
      if (!(OcStatementSummaryKm in this.$store._modules.root._children)) {
        this.$store.registerModule(OcStatementSummaryKm, storeOcStatementSummaryKm);
      }
      if (!(MODULES in this.$store._modules.root._children)) {
        this.$store.registerModule(MODULES, storeModules);
      }
      if (!(AUTH in this.$store._modules.root._children)) {
        this.$store.registerModule(AUTH, storeAuth);
      }
      if (!(USER in this.$store._modules.root._children)) {
        this.$store.registerModule(USER, storeUser);
      }
      if (!(USER_SUB_AGENT in this.$store._modules.root._children)) {
        this.$store.registerModule(USER_SUB_AGENT, storeUserSubAgent);
      }
      if (!(MENUS in this.$store._modules.root._children)) {
        this.$store.registerModule(MENUS, storeMenu);
      }
      if (!(TRANSACTION in this.$store._modules.root._children)) {
        this.$store.registerModule(TRANSACTION, storeTransaction);
      }
      if (!(WidthdrawStatement in this.$store._modules.root._children)) {
        this.$store.registerModule(WidthdrawStatement, storeWidthdrawStatement);
      }
      if (!(DepositStatement in this.$store._modules.root._children)) {
        this.$store.registerModule(DepositStatement, storeDepositStatement);
      }
      if (!(TOPUP in this.$store._modules.root._children)) {
        this.$store.registerModule(TOPUP, storeTopUp);
      }
      if (!(ROLE in this.$store._modules.root._children)) {
        this.$store.registerModule(ROLE, storeRole);
      }
      if (!(PERMISSION in this.$store._modules.root._children)) {
        this.$store.registerModule(PERMISSION, storePermission);
      }
      if (!(CHANNEL in this.$store._modules.root._children)) {
        this.$store.registerModule(CHANNEL, storeChannel);
      }
       if (!(STATEMENT in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT, storeStatement);
      }
       if (!(CockFightChannel in this.$store._modules.root._children)) {
        this.$store.registerModule(CockFightChannel, storeCockFightChannel);
      }
      if (!(STATEMENT_USD in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_USD, storeStatementUsd);
      }
      if (!(STATEMENT_KH in this.$store._modules.root._children)) {
        this.$store.registerModule(STATEMENT_KH, storeStatementKh);
      }
      
      //reverse result
      if (!(ReverseResult in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseResult, storeReverseResult);
      }
      if (!(ReverseBaccarat in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseBaccarat, storeReverseBaccarat);
      }
      if (!(ReverseDragon in this.$store._modules.root._children)) {
        this.$store.registerModule(ReverseDragon, storeReverseDragon);
      }
      //////
      if (!(ConfigUser in this.$store._modules.root._children)) {
        this.$store.registerModule(ConfigUser, storeConfigUser);
      }
      if (!(PayOut in this.$store._modules.root._children)) {
        this.$store.registerModule(PayOut, storePayOut);
      }
       if (!(WEIGHT in this.$store._modules.root._children)) {
        this.$store.registerModule(WEIGHT, storeWeight);
      }
      if (!(RoleLevel in this.$store._modules.root._children)) {
        this.$store.registerModule(RoleLevel, storeRoleLevel);
      }
      if (!(ControllerForManager in this.$store._modules.root._children)) {
        this.$store.registerModule(ControllerForManager, storeControllerForManager);
      }

       if (!(KDPAYOUT in this.$store._modules.root._children)) {
        this.$store.registerModule(KDPAYOUT, storeKdPayout);
      }
    },
    ...mapActions("$_modules", ["fetchUserInfo"]),
  },
};
</script>
<style>
#particles-js{
  position: absolute;
  width: 100% !important;
  height:100% !important;
}
</style>