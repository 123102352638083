<template>
  <div>
    <div class="section-header" hidden>
      <h1>User Role</h1>
      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active"><a href="/">Dashboard</a></div>
        <div class="breadcrumb-item">User Role</div>
      </div>
    </div>
    <div class="section-body">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive" style="text-align: center">
            <v-data-table
              style="font-family: 'khmer mef1'"
              item-key="_id"
              class=" table table-striped"
              id="table-1"
              :headers="headers"
              :items="getRoles && getRoles.object ? getRoles.object : []"
              :footer-props="footerProps"
              :server-items-length="getRoles ? getRoles.objectCount : null"
              :page.sync="data.page"
              :items-per-page.sync="data.rowsPerPage"
              :sort-desc.sync="data.descendingFormat"
              :sort-by.sync="data.sortByFormat"
              hide-default-header
              hide-default-footer
              @update:page="getData"
              @update:items-per-page="getData"
              @update:sort-desc="getData"
              :value="getDeleteItems"
              @input="itemDelete"
              :loading="getLoading"
              show-select
              mobile-breakpoint="0"
            >
              <template v-slot:header="{ props }">
                <th v-for="head in props.headers" :key="head._id">
                  {{ head.text }}
                </th>
              </template>
              <template v-slot:top>
                <Header
                  :darkmode="darkmode"
                  :params="data"
                  :dataDelete="getDeleteItems"
                  :user-permission="userAccessPermission"
                />
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <ActionComponents
                  :darkmode="darkmode"
                  :item="item"
                  :dataDelete="getDeleteItems"
                  :params="data"
                  :user-permission="userAccessPermission"
                />
              </template>
            </v-data-table>
          </div>
          <Dialog
            :darkmode="darkmode"
            :loading="loading"
            :role="getCurrentRole"
            :dialog="getPopUp"
            :selection="getPermissionSelection"
            :tree-menu="getTreeMenu"
            :is-validate.sync="valid"
            v-on:submit="handleSubmit"
            v-on:onCloseDialog="fetchPopUp(false)"
          />
          <div class="text-center pt-2">
            <v-pagination
              v-model="data.page"
              :length="
                getRoles
                  ? getRoles.objectCount % data.rowsPerPage != 0
                    ? getRoles.objectCount / data.rowsPerPage + 1
                    : getRoles.objectCount / data.rowsPerPage
                  : 0
              "
            ></v-pagination>
            <v-text-field
              :value="data.rowsPerPage"
              label="Items per page"
              type="number"
              min="-1"
              max="15"
              @input="data.rowsPerPage = parseInt($event, 10)"
            ></v-text-field>
          </div>
          <div class="loader" v-if="this.loader == true"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/Header.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
  },
  data: () => ({
    valid: true,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: "_id",
      page: 1,
      rowsPerPage: 10,
      fields: ["role_name", "descr", "status", "updatedAt"],
      search: "",
    },
    loading: false,
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("header.role"), align: "start", value: "role_name" },
        {
          text: this.$t("header.description"),
          value: "descr",
          sortable: false,
        },
        {
          text: this.$t("header.created_at"),
          value: "createdAt",
          sortable: false,
        },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
    ...mapGetters("$_menu", ["getTreeMenu"]),
    ...mapGetters("$_role", [
      "getRoles",
      "getPopUp",
      "getCurrentRole",
      "getDeleteItems",
      "getPermissionSelection",
      "getLoading",
    ]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      if (!this.getCurrentRole._id) {
        await this.createRole({ vm: this, data: this.getCurrentRole });
      } else {
        await this.updateRole({ vm: this, data: this.getCurrentRole });
      }
      await this.getData();
      this.loading = false;
    },

    async getData() {
      this.checkUserPermission("VIEW")
        ? await this.fetchListRoles(this.data)
        : [];
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },
    ...mapMutations("$_role", ["DELETE_ITEMS", "UPDATE_ROLES"]),
    ...mapActions("$_role", [
      "fetchListRoles",
      "fetchPopUp",
      "createRole",
      "updateRole",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_ROLES([]);
    this.UPDATE_USER_ACCESS_PERMISSION([]);
    next();
  },
};
</script>
<style>
.users {
  width: 50%;
  float: left;
}

table {
  font-family: "khmer mef1" !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
</style>
