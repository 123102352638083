<template>
  <div>
      <v-row class="justify-center py-5 cm-title" style="">
        Summary Statement Each Channel 
         
      </v-row>
      <v-btn @click="getData()" class=" cm-refresh" color="primary">
        refresh
      </v-btn>
<!--    Display List Front Page-->
     <v-row style="margin-top:150px" elevation="24">
      
        <v-col cols="6" md="3" sm="3" class="my-5 w-100 text-center" >
           <div class="text-h5 mt-5 ">Channel 1</div>
          <br>
          <!-- fight no: <span class="fino">{{  ch ? ch[0].fight_no :"Loading"  }}</span> -->
          <v-data-table
          :headers="headers1"
          :items="StatementObj"
          :server-items-length="3"
          item-key="currency"
          class="elevation-1"
          style="font-family: 'khmer mef1'; border:1px solid black; border-right:0px"
          mobile-breakpoint="0"
          hide-default-header
          hide-default-footer
        >
         <template  v-slot:header="{ props }">
              <template>
                <tr >
                    <th></th>
                 <th v-for="header in props.headers"
                      :key="header.value"
                      :width="header.width"
                      style="padding: 30px 0px 20px 0px; font-size:18px"
                      :class="header.text =='Meron' ? 'lose-color' : header.text =='Wala' ? 'win-color':''">
                    {{ header.text }}
                  </th>
                </tr>
              </template></template>
          <template v-slot:[`item`]="{ item }">
            <tr>
              <td class="text-center">
              {{ item.currency }}
              </td>
              <td :class="item.amount1 < 0 ? 'text-center lose-color text-decoration-underline row-pointer' : 'text-center win-color text-decoration-underline row-pointer'">{{ item.currency =="$" || item.currency =="Baht"  ? currencyFormatEn(item.amount1) : currencyFormatKm(item.amount1)}}</td>
            </tr>
          </template>
          <template slot="body.append">
            <tr >
              <td style="text-align:center " colspan="9" v-if="loader">
                <div class="lds-roller my-3"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </td>
            </tr>
          </template>
          </v-data-table>
        </v-col>
        <v-col cols="6" md="3" sm="3" class="my-5 w-100 text-center">
             <div class="text-h5 mt-5 ">Channel 2</div>
          <br>
          <!-- fight no: <span class="fino">{{  ch ? ch[1].fight_no :"Loading"   }}</span> -->
          <v-data-table
          :headers="headers2"
          :items="StatementObj"
          :server-items-length="3"
          item-key="currency"
          class="elevation-1"
          style="font-family: 'khmer mef1';  border:1px solid black; border-right:0px; border-left:0px"
          mobile-breakpoint="0"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:header="{ props }">
              <template >
                <tr>
                  <th  v-for="header in props.headers"
                      :key="header.value"
                      :width="header.width"
                      style="padding: 30px 0px 20px 0px; font-size:18px"
                      :class="header.text =='Meron' ? 'lose-color' : header.text =='Wala' ? 'win-color':''">
                    {{ header.text }}
                  </th>
                </tr>
              </template></template>
          <template v-slot:[`item`]="{ item }">
            <tr>
              <td :class="item.amount2 < 0 ? 'text-center lose-color text-decoration-underline row-pointer' :'text-center win-color text-decoration-underline row-pointer'">{{ item.currency =="$" || item.currency =="Baht"  ? currencyFormatEn(item.amount2) : currencyFormatKm(item.amount2)}}</td>
            </tr>
          </template>
          <template slot="body.append">
            <tr >
              <td style="text-align:center " colspan="9" v-if="loader">
                <div class="lds-roller my-3"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </td>
            </tr>
          </template>
          </v-data-table>
        </v-col>
        <v-col cols="6" md="3" sm="3" class="my-5  w-100 text-center">
            <div class="text-h5 mt-5 ">Channel 3</div>
           <br>
          <!-- fight no: <span class="fino">{{ ch ? ch[2].fight_no :"Loading"  }}</span> -->
          <v-data-table
            :headers="headers3"
            :items="StatementObj"
            :server-items-length="3"
            item-key="currency"
            class="elevation-1"
            style="font-family: 'khmer mef1';  border:1px solid black; border-right:0px; border-left:0px"
            mobile-breakpoint="0"
            hide-default-header
            hide-default-footer
          >
            <template v-slot:header="{ props }">
              <template >
                <tr>
                  <th  v-for="header in props.headers"
                      :key="header.value"
                      :width="header.width"
                      style="padding: 30px 0px 20px 0px; font-size:18px"
                      :class="header.text =='Meron' ? 'lose-color' : header.text =='Wala' ? 'win-color':''">
                    {{ header.text }}
                  </th>
                </tr>
              </template>
          </template>
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td :class="item.amount3 < 0 ? 'text-center lose-color text-decoration-underline row-pointer' 
                    :'text-center win-color text-decoration-underline row-pointer'">{{ item.currency =="$" || item.currency =="Baht"  ? currencyFormatEn(item.amount3) : currencyFormatKm(item.amount3)}}</td>
              </tr>
            </template>
            <template slot="body.append">
              <tr >
                <td style="text-align:center " colspan="9" v-if="loader">
                  <div class="lds-roller my-3"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="6" md="3" sm="3" class="my-5 w-100 text-center">
           <div class="text-h5 mt-5 ">Channel 4</div>
           <br>
            <!-- fight no: <span class="fino">{{ ch ? ch[3].fight_no :"Loading" }}</span> -->
          <v-data-table
          :headers="headers4"
          :items="StatementObj"
          :server-items-length="3"
          item-key="currency"
          class="elevation-1"
          style="font-family: 'khmer mef1'; border:1px solid black; border-left:0px"
          mobile-breakpoint="0"
          hide-default-header
          hide-default-footer
        >
           <template v-slot:header="{ props }">
              <template >
                <tr>
                  <th v-for="header in props.headers"
                      :key="header.value"
                      :width="header.width"
                      style="padding: 30px 0px 20px 0px; font-size:18px"
                      :class="header.text =='Meron' ? 'lose-color' : header.text =='Wala' ? 'win-color':''">
                    {{ header.text }}
                  </th>
                </tr>
              </template></template>
          <template v-slot:[`item`]="{ item }">
            <tr>
               <td :class="item.amount4 < 0 ? 'text-center lose-color text-decoration-underline row-pointer' :'text-center win-color text-decoration-underline row-pointer'">{{ item.currency =="$" || item.currency =="Baht"  ? currencyFormatEn(item.amount4) : currencyFormatKm(item.amount4)}}</td>
            </tr>
          </template>
          <template slot="body.append">
            <tr >
              <td style="text-align:center " colspan="9" v-if="loader">
                <div class="lds-roller my-3"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </td>
            </tr>
          </template>
          </v-data-table>
        </v-col>
      </v-row>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";


export default {
  components: {
  },

  data: () => ({
    headers1: [
                {
                    text: 'សរុបចាញ់ឈ្នះ',
                    value: 'Meron_Ch1',
                    align: 'left'
                },
    ],
    headers2:  [
                {
                     text: 'សរុបចាញ់ឈ្នះ',
                    value: 'Meron_Ch2',
                    format: (v) => v && Number(v).toFixed(2),
                    align: 'left'
                },
       
    ],
    headers3: [
        
                {
                        text: 'សរុបចាញ់ឈ្នះ',
                    value: 'Meron_Ch3',
                    format: (v) => v && Number(v).toFixed(2),
                    align: 'left'
                },
    ],
    headers4: [
                {
                        text: 'សរុបចាញ់ឈ្នះ',
                    value: 'Meron_Ch4',
                },
         
    ],
    StatementObj :[
      {
        currency:"$",
        amount1:0,
        amount2:0,
        amount3:0,
        amount4:0
      },
      {
        currency:"៛",
        amount1:0,
        amount2:0,
        amount3:0,
        amount4:0
      },
      {
        currency:"Baht",
        amount1:0,
        amount2:0,
        amount3:0,
        amount4:0
      },
    ],
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },

    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: [
        "co_type_of_betting.name", "co_type_of_betting.desc", "co_type_of_betting.color", "date", "fight_no", "is_win", "amount", "amount_win", "cast"
      ],
      search: "",
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
    },
    dialog: false,
    loading: false,
    loader: false,
    detailData:[],
    detailDataCount:[],
    ch:[],
    confirmDialog:false,
    targetChannel:[],
    channel_type : null,
     dataRequest:{
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: ["user_name","co_type_of_betting.name", "co_type_of_betting.desc", "co_type_of_betting.color", "date", "fight_no", "is_win", "amount", "amount_win", "cast"],
      search: "",
      fightNo:null
    },
    
  }),
  watch: {
    getStatement: function(newV) {
      if(newV) this.getFirstData()
    },
    StatementObj:function(){
    },
    ch:function(){}

  },
  computed: {
    ...mapGetters("$_modules", {user: "getUser" }),
    ...mapGetters({channels: "getChannel" }),
  },
  async created() {
    this.getData();
    this.loadChannel()
  },
  async mounted() {
  },
  methods: {
    moment,
    currencyFormatEn(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
     currencyFormatKm(num) {
      num = parseInt(num);
      return num.toLocaleString("en");
    },
  

    async getData() {
      try {
        this.loader = true
        await this.$request
          .get({
            url: "adminReport/getViewSummaryWinLoseByGroup/",
          })
          .then((res) => {
            if (res.data.code) { 
              // console.log("Control by chanel", res.data)
              this.getStatement = res.data.data.object;
              this.getCountStatement = res.data.data.objectCount;
              this.getFirstData();
              this.loading = false;
            }else{
              
              this.loading = false;
            }
          });

          this.loader = false;
      } catch (error) {
        // console.log(error)
      }
    },
    getFirstData(){
      // console.log("hi",this.getStatement[0].length)
        for(let i = 0; i < this.getStatement[0].length; i++){
          //  console.log(this.getStatement[0][0]._id.currency)
          if(this.getStatement[0][i]._id.currency =="D"){
            if(this.getStatement[0][i]._id.channel == 'Channel-1'){            
                this.StatementObj[0].amount1 = this.getStatement[0][i].amount_win 
              //  console.log("this.StatementObj[0].amountD1",this.StatementObj[0].amountD1)
            }
            if(this.getStatement[0][i]._id.channel == 'Channel-2'){            
                this.StatementObj[0].amount2 = this.getStatement[0][i].amount_win 
                // console.log( " this.StatementObj[0].amountD2",this.StatementObj[0].amountD2)
            }
            if(this.getStatement[0][i]._id.channel == 'Channel-3'){            
                this.StatementObj[0].amount3 = this.getStatement[0][i].amount_win 
                // console.log( " this.StatementObj[0].amountD3",this.StatementObj[0].amountD3)
            }
            if(this.getStatement[0][i]._id.channel == 'Channel-4'){            
                this.StatementObj[0].amount4 = this.getStatement[0][i].amount_win 
                // console.log( " this.StatementObj[0].amountD4",this.StatementObj[0].amountD4)
            }
          }
         }//  console.log("helo",this.getStatement[1])
          for(let i = 0; i<this.getStatement[1].length; i++){
            if(this.getStatement[1][i]._id.currency =="R"){
              if(this.getStatement[1][i]._id.channel == 'Channel-1'){            
                  this.StatementObj[1].amount1 = this.getStatement[1][i].amount_win 
                  //  console.log( " this.StatementObj[0].amountR1",this.StatementObj[0].amountR1)
              }
              if(this.getStatement[1][i]._id.channel == 'Channel-2'){            
                  this.StatementObj[1].amount2 = this.getStatement[1][i].amount_win 
                  //  console.log( " this.StatementObj[0].amountR2",this.StatementObj[0].amountR2)
              }
              if(this.getStatement[1][i]._id.channel == 'Channel-3'){            
                  this.StatementObj[1].amount3 = this.getStatement[1][i].amount_win 
                  //  console.log( " this.StatementObj[0].amountR3",this.StatementObj[0].amountR3)
              }
              if(this.getStatement[1][i]._id.channel == 'Channel-4'){            
                  this.StatementObj[1].amount4 = this.getStatement[1][i].amount_win 
                  //  console.log( " this.StatementObj[0].amountR4",this.StatementObj[0].amountR4)
              }
            }
          }
          for(let i = 0; i<this.getStatement[2].length; i++){
            if(this.getStatement[2][i]._id.currency =="B"){
              if(this.getStatement[2][i]._id.channel == 'Channel-1'){            
                  this.StatementObj[2].amount1 = this.getStatement[2][i].amount_win 
              }
              if(this.getStatement[2][i]._id.channel == 'Channel-2'){            
                  this.StatementObj[2].amount2 = this.getStatement[2][i].amount_win 
              }
              if(this.getStatement[2][i]._id.channel == 'Channel-3'){            
                  this.StatementObj[2].amount3 = this.getStatement[2][i].amount_win 
              }
              if(this.getStatement[2][i]._id.channel == 'Channel-4'){            
                  this.StatementObj[2].amount4 = this.getStatement[2][i].amount_win 
              }
            }
          }

      }
      //  console.log("hello world", this.StatementObj[0])
    },
    async loadChannel(){
      this.getLoading = true;
     await this.$request
        .get({
          url: "coChannel/getCurrentChannel",
          data: this.dataRequest,
        })
        .then((res) => {
          if (res.data.code) {
            this.ch = res.data.data.objCurrentChannel;
            // this.detailDataCount = res.data.data.objectCount;
            this.getLoading = false;
          }else{
            this.getLoading = false;
          }
        });
    },
    searchq() {
      this.getLoading = true;
      this.dataRequest.search = this.v_search;
      this.updatePage()
    },
    updatePage() {
      this.getLoading = true;
      this.loadDetailData();
      this.getLoading = false;
    },
    _updateChanel(ch, channelType){
      this.targetChannel = ch;
      this.channel_type = channelType
      this.confirmDialog = true;
    },
    _closeDialog(){
       this.targetChannel = [];
       this.channel_type = null
       this.confirmDialog = false;
    },
    async _takeAction(){

      let data = {
        enable : !this.targetChannel.enable,
        channel_type : this.channel_type
      }
      // console.log("_takeAction", this.targetChannel, this.channel_type)
      await this.$request
        .post({
          url: "coChannel/updateEnableStatusChannel",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.loadChannel();
            this.getLoading = false;
          }else{
            // console.log(res.data)
            this.getLoading = false;
          }
        });
      // console.log("_takeAction", this.targetChannel, this.channel_type)
      this.targetChannel = [];
      this.channel_type = null
      this.confirmDialog = false;
    },
    ...mapMutations("$_controlStatementByChannel", ["LIST_STATEMENT"]),
    ...mapActions("$_controlStatementByChannel", ["fetchListStatement", "fetchGetCurrentChannel"]),
  
};
</script>

<style lang="scss" scoped>
.fino{
  color:#6699cc ;
  font-size:24px;
  font-weight:bold;
  margin-left:10px

}
.cm-title{
  color:black;
  font-weight: 900;
  font-size: 32px;
  position:absolute; top:90px; left:40%; margin-left: -90px  ;
}
.cm-refresh {
  color:black;
  font-weight: 900;
  font-size: 32px;
  position:absolute; top:105px; left:90%; margin-left: -90px  ;
}
.row-pointer:hover {
  cursor: pointer;
  background-color: honeydew;

}
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
.amount-bet-color {
  color: goldenrod !important;
}

.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
.tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #cef;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
