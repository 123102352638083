<template>
      <!-- <v-flex class="form-section-container">
        <div id="wrapper">
            <div class="auth-page login-wrapper">
                <div class="login-panel position-relative pt-5">
                    <div v-if="isOTPShow == true" class="otp-layout">
                        <div class="otp-label">INPUT OTP CODE</div>
                        <v-otp-input class="justify-content-center" ref="otpInput" inputClasses="otp-input"
                            :numInputs="6" :is-input-num="true" separator="-" :shouldAutoFocus="true"
                            @on-complete="handleOnComplete" @on-change="handleOnChange" />
                        <div v-if="isOTPError" class="otp-error">{{ otpErrorMgs }}</div>
                    </div>                    
                    <div v-else-if="isOTPShow == false">
                        <div class="card card-primary">
                            <div class="card-body">
                                <v-form ref="form" lazy-validation @submit.prevent="login">
                                    <div style="width:100%; display: flex; justify-content: center;">
                                        <img src="/images/logo.png" 
                                        class="my-2 mx-auto justify-center align-center" 
                                        style="width:70px; height:70px"/>
                                    </div>
                                    
                                    <hr class="my-5 bg-gray-400 opacity-50" />
                                    <div v-if="error" class="mb-4 text-danger text-center">
                                        {{ description }}
                                    </div>
                                
                                        <div class="form-group">
                      <label>User name</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <i class="fas fa-user"></i>
                          </div>
                        </div>
                        <input type="text" class="form-control phone-number" v-model="user.user_name" label="Username" :rules="rules" required
                                        placeholder="Enter your username" @input="error = false">
                      </div>
                    </div>
                                    <div class="form-group">
                                        <label>Password Strength</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    <i class="fas fa-lock"></i>
                                                </div>
                                            </div>
                                            <input type="password" class="form-control pwstrength"
                                                data-indicator="pwindicator" v-model="user.password"  label="Password" :rules="rules"
                                        required  placeholder="Enter your password"
                                        @input="error = false">
                                        </div>
                                        <div id="pwindicator" class="pwindicator">
                                            <div class="bar"></div>
                                            <div class="label"></div>
                                        </div>
                                    </div>
                                    <div class="flex justify-end mt-3">
                                        <v-btn :loading="loading" type="submit" :disabled="loading" color="primary"
                                            style="background-color: #6975E2; width: 100%; margin: 0 !important;">
                                            Login
                                        </v-btn>
                                    </div>
                                </v-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-flex> -->

   <v-flex class="form-section-container">
        <div id="wrapper">
            <div class="auth-page login-wrapper">
                <div class="login-panel position-relative">
                    <div class="login-header py-2">
                        <img :src="formLogo" class="d-block mx-auto login-header-img w-50">
                    </div>
                    <!-- <h6 class="text-center auth-via">Login via</h6> -->
                    <div v-if="isOTPShow == true" class="otp-layout">
                        <div class="otp-label">INPUT OTP CODE</div>
                        <v-otp-input class="justify-content-center" ref="otpInput" inputClasses="otp-input"
                            :numInputs="6" :is-input-num="true" separator="-" :shouldAutoFocus="true"
                            @on-complete="handleOnComplete" @on-change="handleOnChange" />
                        <div v-if="isOTPError" class="otp-error">{{ otpErrorMgs }}</div>
                    </div>                    
                    <div v-else-if="isOTPShow == false">
                    <v-form ref="form" v-model="validate" lazy-validation @submit.prevent="login" class="form-container">
                        <div class="form-group fg-container pt-2">
                            <div class="btn-login-title">
                                <div class="auth-field-opt btn-group btn-group-toggle justify-content-between flex w-100 align-items-center my-2 fg-container">
                                    <button type="button" class="btn btn-tab-radio px-1 active focus btn-tab-switch my-2">
                                        {{ $t("title.login") }}
                                    </button>
                                    <!-- <button type="button" class="btn btn-tab-radio ml-1 btn-tab-switch">
                                        Email
                                    </button> -->
                                </div>
                                <input v-model="user.user_name" placeholder="Username" label="Username" :rules="[rules.required]" required type="text" id="username" class="form-control" @input="error = false"/>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="eye-toggable" style="position: relative;">
                                <input  v-model="user.password" :rules="[rules.required, rules.min]" placeholder="Password" :type="!isShowText ? 'password':'text'" required autocomplete="on" class="form-control" @input="error = false"/>
                               
                                <div class="eye" @click="isShowText = !isShowText">
                                    <svg class="svg-inline--fa fa-eye fa-w-16" data-prefix="fas" data-icon="eye"
                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                                        data-fa-i2svg="">
                                        <path fill="currentColor"
                                            d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z">
                                        </path>
                                    </svg>
                                    <!-- <i class="fas fa-eye"></i> -->
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <button :loading="loading" type="submit" :disabled="loading" class="btn btn-secondary btn-user btn-block mb-1 fg-container">
                                {{$t('title.log')}}
                            </button> 
                            
                        </div>
                    </v-form>
                    </div>
                    <!-- <a href="https://goperya.com/platform/register" id="login-screen-register"
                        class="btn btn-gold btn-user btn-block mt-3">
                        Register
                    </a>
                    <div class="text-center mb-2 mt-2">
                        <a class=" text-white" href="https://goperya.com/platform/reset/mobile">Forgot
                            Password</a>
                    </div> -->
                   
                </div>
            </div>
        </div>
        <!-- <div class="py-3 outlet">
            <div class="outlet-container mx-auto py-2 py-sm-3">
                <hr class="line-seperate-form">
                <img class="img-fluid-cash px-5 pb-5" src="//static3.goperya.com/img/playnow_using.png?v=10.60">
                <div class="row mt-4 mt-sm-0">
                    <div class="col-12 col-sm-4 py-1 py-sm-0 px-5 px-sm-0">
                        <div class="row d-flex flex-column align-items-center">
                            <div class="col-5 col-sm-12">
                                <img class="p-2 p-sm-4 img-fluid-cash" style="    padding: 0px 13px!important;"
                                    src="//static3.goperya.com/landing/img/gcash.png?v=10.60">
                            </div>
                            <div class="col-8 col-sm-12 text-center"><span>GCash</span></div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 my-3 my-sm-0 px-5 px-sm-0 pt-0">
                        <div class="row d-flex flex-column align-items-center">
                            <div class="col-5 col-sm-12">
                                <img class="p-2 p-sm-4 img-fluid-cash"
                                    src="//static3.goperya.com/img/paymaya.png?v=10.60">
                            </div>
                            <div class="col-8 col-sm-12 text-center"><span>PayMaya</span></div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 py-1 py-sm-0 px-5 px-sm-0">
                        <div class="row d-flex flex-column align-items-center">
                            <div class="col-5 col-sm-12">
                                <img class="p-2 p-sm-4 img-fluid-cash" src="//static3.goperya.com/img/visa.png?v=10.60">
                            </div>
                            <div class="col-8 col-sm-12 text-center"><span>Visa Mastercard</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <nav class="fab-container fab-landing">
            <a class="buttons main-fab animated infinite pulse delay-2s flex-v-center" href="#" target="_blank"
                id="chatBtn" data-toggle="tooltip" data-placement="left" title="Need help? Chat with us!">
                <svg  viewBox="0 0 36 36"
                    style="width:45px;"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
                    role="img" class="svg-inline--fa fa-user-headset fa-w-14 fab-el-ic iconify iconify--twemoji" preserveAspectRatio="xMidYMid meet" fill="#000000">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path fill="#F4900C" d="M14.174 17.075L6.75 7.594l-3.722 9.481z"></path>
                        <path fill="#F4900C" d="M17.938 5.534l-6.563 12.389H24.5z"></path>
                        <path fill="#F4900C" d="M21.826 17.075l7.424-9.481l3.722 9.481z"></path>
                        <path fill="#FFCC4D"
                            d="M28.669 15.19L23.887 3.523l-5.88 11.668l-.007.003l-.007-.004l-5.88-11.668L7.331 15.19C4.197 10.833 1.28 8.042 1.28 8.042S3 20.75 3 33h30c0-12.25 1.72-24.958 1.72-24.958s-2.917 2.791-6.051 7.148z">
                        </path>
                        <circle fill="#5C913B" cx="17.957" cy="22" r="3.688"></circle>
                        <circle fill="#981CEB" cx="26.463" cy="22" r="2.412"></circle>
                        <circle fill="#DD2E44" cx="32.852" cy="22" r="1.986"></circle>
                        <circle fill="#981CEB" cx="9.45" cy="22" r="2.412"></circle>
                        <circle fill="#DD2E44" cx="3.061" cy="22" r="1.986"></circle>
                        <path fill="#FFAC33"
                            d="M33 34H3a1 1 0 1 1 0-2h30a1 1 0 1 1 0 2zm0-3.486H3a1 1 0 1 1 0-2h30a1 1 0 1 1 0 2z">
                        </path>
                        <circle fill="#FFCC4D" cx="1.447" cy="8.042" r="1.407"></circle>
                        <circle fill="#F4900C" cx="6.75" cy="7.594" r="1.192"></circle>
                        <circle fill="#FFCC4D" cx="12.113" cy="3.523" r="1.784"></circle>
                        <circle fill="#FFCC4D" cx="34.553" cy="8.042" r="1.407"></circle>
                        <circle fill="#F4900C" cx="29.25" cy="7.594" r="1.192"></circle>
                        <circle fill="#FFCC4D" cx="23.887" cy="3.523" r="1.784"></circle>
                        <circle fill="#F4900C" cx="17.938" cy="5.534" r="1.784"></circle>
                    </g>
                </svg>
            </a>
        </nav> -->
    </v-flex>




  
</template>

<script>
import "@/assets/css/login.css";
import { mapActions } from "vuex";

export default {
   
    data: () => ({
        validate: false,
        formLogo: '/images/logo.png',
        isShowText : false,
        user: {
            user_name: null,
            password: null,
            app_id: null
        },
        rules: [(value) => !!value || "Required."],
        loading: false,
        error: false,
        isOTPShow: false,
        tmpChannelUserId: null,
        show: false,
        isOTPError: false,
        otpErrorMgs: "Wrong OTP",
        tempChannelUser: null,
        description: ""
    }),
    methods: {
        reset() {
            this.$refs.form.reset();
        },
        async handleOnComplete(value) {
             console.log('Hello...');
            try {
                let otpData = {
                    user_id: this.tmpChannelUserId,
                    otp: value
                }
                const response = await this.fetchVerifyOtp(otpData)
                if (response.data.code) {
                    //success
                    let rolee = this.tempChannelUser.role
                    this.$cookies.set("token", response.data.data.token);
                    this.$cookies.set("channel-type", 1);
                    this.$cookies.set("user_role", rolee);
                    this.$cookies.set("local", 'kh');
                    this.$cookies.set("user", this.tempChannelUser);
                    this.$router.push({ path: "/dashboard" });
                } else {
                    this.$refs.otpInput.clearInput();
                    this.otpErrorMgs = response.data.message.descriptions;
                    this.isOTPError = true;
                }

            } catch (error) {
                this.$refs.otpInput.clearInput();
                this.otpErrorMgs = error.message;
                this.isOTPError = true;
            }
        },
        handleOnChange() {
            this.isOTPError = false;
        },
       
        async login() {
            console.log("login");
            if (this.$refs.form.validate()) {
                console.log("login validate");
                try {
                    this.loading = true;
                    this.user.app_id = process.env.VUE_APP_APP_ID;
                    const response = await this.fetchLogIn(this.user);
                    if (response.data.code
                        //condition dnt let user role player login in to admin system
                        && response.data.data.user.role != "61829df5e64b1b1768832700"
                        && response.data.data.user.role != "61829dfee64b1b1768832709"
                        && response.data.data.user.role != "61829e05e64b1b1768832712") {
                        const { token, uuid, user } = response.data.data;

                        if (token == "") {
                            this.$cookies.set("uuid", uuid);
                            this.tempChannelUser = user
                            this.tmpChannelUserId = user._id
                            this.isOTPShow = true
                        } else {
                            let rolee = user.role
                            this.$cookies.set("token", token);
                            this.$cookies.set("user_role", rolee);
                            this.$cookies.set("channel-type", 1);
                            this.$cookies.set("local", 'kh');
                            this.$cookies.set("user", user);
                            if(response.data.data.user.role == "6191dc644825e6f57ffa231f" || response.data.data.user.role == "6191dce94825e65937fa2340" || response.data.data.user.role == "6424080f50b77c6cf890ab11" || response.data.data.user.role == "6472bfce1ba4b8a1604940c2"){
                                this.$router.push({ path: "/userSubAgent" });
                            }else{
                                this.$router.push({ path: "/users" });
                            }
                        }

                    } else {
                        this.error = true;
                        this.description = response.data.message.descriptions;
                    }
                } catch (error) {
                    this.error = true;
                    return error;
                } finally {
                    this.loading = false;
                }
            }
        },
        ...mapActions("$_auth", ["fetchLogIn", "fetchVerifyOtp"]),
    },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&family=Siemreap&display=swap');
</style>

<style lang="scss" >
.siepream {
    font-family: 'Roboto', 'Siemreap', cursive, sans-serif;

}

.info {
    color: #28546C;
    font-weight: bold;
    padding-left: 25px;
}

.info-top {
    padding-top: 10px
}

.otp-layout {
    border: 1px solid hsla(0, 0%, 100%, 0.05);
    border-radius: 4px;
    padding: 20px;
    margin: auto;
    background-color: white;
    width: fit-content;
    height: fit-content;
}

.otp-label {
    color: black;
    text-shadow: red;
    font-size: 18px;
    text-align: center;
    margin: 8px;
    margin-bottom: 16px;
}

.otp-error {
    color: #f34141;
    text-shadow: #f8f6f6;
    font-size: 13px;
    text-align: center;
    margin: 8px;
}

.otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;

    &.error {
        border: 1px solid red !important;
    }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.m-x-login {
    margin: 0px 20px;
}

</style>