import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  currentChannel: {
    _id: null,
    tem_id: null,
    info: null,
    video_info: null,
    date: null,
    fight_no: null,
    live_urls: [],
    maintain_desc: null,
    is_maintain: null,
    // enable: null,
    is_open: null,
    is_stop_video: null,
  },
  objCoResult: [],
  objCoTypeOfBetting: [],
  results: [],
  objSummaryCoResult: {
    P: 0,
    B: 0,
    T: 0,
    PB: 0,
    PP: 0,
  },
  loading: false,
  loadingResult: false,
  objKdPayout: [],
  dialogResult: false,
  lastResult: {},
  resultSkipColumn: {},
  resultSkipRow: {},
  isNoResultBetween: {},
  dealerInfo: {},
  dealerDialog: false,
  resultEvent: false,
  currentCard: {},
  allowScanCard: false,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
