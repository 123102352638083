<template>
    <v-flex class="login-logo-section p-5">
        <div class="m-auto text-center">
            <img class="hero-img img-fluid mb-5" src="/images/logo.png" style="width:50%">
           
        </div>
    </v-flex>
</template>   
<script>
// import "@/assets/css/base.css";
import "@/assets/css/login.css";
export default {

}
</script>