const headers = [
    //{ text: 'សកម្មភាព', align: 'start', value: 'actions', sortable: false },
    {
        text: 'ល.រ',
        align: 'start',
        value: 'no',
    },
    { text: 'App Name', value: 'app_id' },
    { text: 'Fight No', value: 'fight_no' },
    { text: 'Amount Meron', value: 'amount_meron' },
    { text: 'Amount Tie', value: 'amount_tie' },
    { text: 'Amount Wala', value: 'amount_wala' },
    { text: 'Cast', value: 'cast' },
    { text: 'Amount Win', value: 'amount_win' },
    // { text: 'VIP option', value: '' },
];

export default headers;